import React , { useEffect } from "react"
import UserStore from '../components/auth/UserStore'


function edit_member(member_id){
    var form = document.getElementById("edit_user");
    const data = new FormData(form);
    const value = Object.fromEntries(data.entries());
    value.id = member_id;
    let number = data.get('number');
    let birthday = data.get('birthday');
    if(birthday.length != 10){
        alert("Wrong birthday format, Right format is åååå-mm-dd")
    }

    if((number.length != 12) || (number.slice(0, 3) != "+46")){
        alert("Wrong phone number format, Right format is +46000000000")
        return;
    }

    fetch("/api/edit_member",{
        method: "post",
        headers:{
          'Content-type': 'application/json; charset=UTF-8' 
        },
        body: JSON.stringify(value)
    })
    .then(res => {
        if (res.status === 200){
            console.log("Edited the member")
            window.location.replace("/members")
        }
        else{
            alert("Cant edit member right now, try agin!")
        }
      })
    .catch(err => {
        alert(err)
    });
    console.log(JSON.stringify(value));
}

let all_citys = [
    "Borås",
    "Gislaved",
    "Göteborg",
    "Halmstad",
    "Helsingborg",
    "Jönköping",
    "Landskrona",
    "Linköping",
    "Malmö",
    "Norrköping",
    "Skövde",
    "Stockholm",
    "Trelleborg",
    "Trollhättan",
    "Västerås",
    "Växjö",
]


class EditMember extends React.Component{    
    change_gender = (e) => {
        const user_info_new = this.state.user_info;
        user_info_new.gender = e;
        this.setState({user_info: user_info_new})
    }
    change_city = (e) => {
        const user_info_new = this.state.user_info;
        user_info_new.city = e;
        this.setState({user_info: user_info_new})
    }
    constructor() {
        super();
        this.state = {
            user: null,
            user_info: []
        }; 
    }
  
    componentDidMount() { 
        let user =UserStore.getUser();
        this.setState({user: user});
        let url = '/api/members/' + this.props.match.params.id
        fetch(url, {method: "get"})
        .then(res => {
        if (res.status === 200) {
            res.json().then(data =>{
            this.setState({ user_info: data})
            console.log(data)
            })               
        } else {
            if (res.statusText == "Unauthorized"){
                let res = fetch('/api/logout',{method: 'get'});
                UserStore.removeAll();
                window.location.replace("/login");
                return;
            }
            const error = new Error(res.error);
            throw error;
        }
        })
        .catch(err => {
            alert("There has been a error, try agin!")
        });

    }


    render(){
        if (this.state.user == null) {
            return "Loading...";
        }
        if (this.state.user_info == null){
            return (
            <>

                <div className="container mx-auto  p-2 mt-5">
                    <h1>No member with id {this.props.match.params.id } was found!</h1>
                </div>
            </>
        )
        }
        return(
            <div>

                <div className="container mx-auto p-2">
                <form id="edit_user">
                    <div className="relative p-10 flex-auto">
                        
                        <div className=" overflow-hidden sm:rounded-md">
                            <div className="px-4 py-5 bg-white sm:p-6">
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                    Namn
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    id="first-name"
                                    defaultValue={this.state.user_info.name}
                                    autocomplete="off"
                                    className="mt-1 p-2 border-solid border-2 border-light-gray-500 border-solid border-2 border-light-gray-500 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                    Efternamn
                                </label>
                                <input
                                    type="text"
                                    name="lastname"
                                    id="last-name"
                                    defaultValue={this.state.user_info.lastname}
                                    autocomplete="off"
                                    className="mt-1 p-2 border-solid border-2 border-light-gray-500 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                </div>

                                <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                                <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                                    Föddelsedag
                                </label>
                                <input
                                    type="date"
                                    name="birthday"
                                    id="birthday"
                                    placeholder="åååå-mm-dd"
                                    defaultValue={this.state.user_info.birthday}
                                    autocomplete="off"
                                    className="mt-1 p-2 border-solid border-2 border-light-gray-500 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                </div>
                                <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                                    <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                        Kön:
                                    </label>
                                    <select name="gender" id="gender" value={this.state.user_info.gender} onChange={(e) => this.change_gender(e.target.value)} className="form-select block w-full mt-1">
                                        <option value="" >----Välj kön---</option>
                                        <option value="Female">Kvinna</option>
                                        <option value="Male">Man</option>
        
                                    </select>
                                </div>
 
                                <div className="col-span-6">
                                <label htmlFor="number" className="block text-sm font-medium text-gray-700">
                                    Nummer
                                </label>
                                <input
                                    type="text"
                                    name="number"
                                    id="number"
                                    maxLength="12"
                                    defaultValue={this.state.user_info.number}
                                    placeholder="+46"
                                    autocomplete="off"
                                    className="mt-1 p-2 border-solid border-2 border-light-gray-500 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                </div>
              
                                <div className="col-span-6">
                                <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    defaultValue={this.state.user_info.email}
                                    placeholder="email"
                                    autocomplete="off"
                                    className="mt-1 p-2 border-solid border-2 border-light-gray-500 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                </div>

                                <div className="col-span-6">
                                <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                                    Adress
                                </label>
                                <input
                                    type="text"
                                    name="address"
                                    id="street-address"
                                    defaultValue={this.state.user_info.address}
                                    placeholder="Adress"
                                    className="mt-1 p-2 border-solid border-2 border-light-gray-500 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                </div>

                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                    <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                        Stad:
                                    </label>
                                    <select name="city" id="city" value={this.state.user_info.city} onChange={(e) => this.change_city(e.target.value)} className="form-select block w-full mt-1">
                                        <option value="">----Välj en stad----</option>

                                        {all_citys.map((city) => (
                                            <option value={city} >{city}</option>
                                        ))}

                                    </select>
                                </div>

                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                                    Ort
                                </label>
                                <input
                                    type="text"
                                    name="ort"
                                    placeholder="ort"
                                    defaultValue={this.state.user_info.ort}
                                    id="ort"
                                    className="mt-1 p-2 border-solid border-2 border-light-gray-500 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                </div>

                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                <label htmlFor="postcode" className="block text-sm font-medium text-gray-700">
                                Postnummer
                                </label>
                                <input
                                    type="text"
                                    name="postcode"
                                    id="postcode"
                                    defaultValue={this.state.user_info.postcode}
                                    placeholder="postcode"
                                    className="mt-1 p-2 border-solid border-2 border-light-gray-500 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    </form>
                    <div className="flex items-center justify-end p-6 border-solid border-blueGray-200 rounded-b">
                    <button
                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => window.location.replace("/members")}
                    >
                        Back
                    </button>
                    <button
                        className="bg-green-400 hover:bg-green-500 text-white font-bold py-2 px-6 rounded-full"
                        type="button"
                        onClick={() => edit_member(this.props.match.params.id )}
                    >
                        Save
                    </button>
                    </div>
                </div>
                </div>

        );
    }
}

export default EditMember;