import { set } from 'mobx';
import React, { useState, useEffect } from 'react';


const AuthMember = (props) => {
    let member_token = props.match.params.id;
    let member_url = props.match.params.url;
    const [success, setSuccess] = useState(null);
    const [done, setDone] = useState(false);
    const [accept, setAccept] = useState(true);

    const [name, setName] = useState('');
    const [lastname, setLastname] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [ort, setOrt] = useState('');
    const [postcode, setPostcode] = useState('');

    const [errorName, setErrorName] = useState(false);
    const [errorLastname, setErrorLastname] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorAddress, setErrorAddress] = useState(false);
    const [errorOrt, setErrorOrt] = useState(false);
    const [errorPostcode, setErrorPostcode] = useState(false);

    const onStateChange = (e) => {
        if (e.target.name === 'name') {
            setErrorName(false);
            if (e.target.value === '') {
                setErrorName(true);
            }
            setName(e.target.value);
        }

        if (e.target.name === 'lastname') {
            setErrorLastname(false);
            if (e.target.value === '') {
                setErrorLastname(true);
            }
            setLastname(e.target.value);
        }

        if (e.target.name === 'email') {
            setErrorEmail(false);
            if (e.target.value === '') {
                setErrorEmail(true);
            }
            setEmail(e.target.value);
        }

        if (e.target.name === 'address') {
            setErrorAddress(false);
            if (e.target.value === '') {
                setErrorAddress(true);
            }
            setAddress(e.target.value);
        }

        if (e.target.name === 'ort') {
            setErrorOrt(false);
            if (e.target.value === '') {
                setErrorOrt(true);
            }
            setOrt(e.target.value);
        }

        if (e.target.name === 'postcode') {
            setErrorPostcode(false);
            if (e.target.value === '') {
                setErrorPostcode(true);
            }
            setPostcode(e.target.value);
        }
    }

    useEffect(async () => {

        let res = await fetch("/api/auth_member", {
            method: "POST",
            headers: {
            "accept": "application/json",
            'Content-type': 'application/json; charset=UTF-8'
            },
            body: JSON.stringify({
                "token":member_token,
                "url":member_url
            })
        })
        let result = await res.json();
        if (result && res.ok){
            if(result["detail"] === "success"){
                setName(result["name"]);
                setLastname(result["lastname"]);
                setEmail(result["email"]);
                setAddress(result["address"]);
                setOrt(result["ort"]);
                setPostcode(result["postcode"]);
                console.log(result);
                setSuccess(true)
            }
            else{
                setSuccess(false)
            }
        }
    }, []);

    const spara_member = async () => {
        if( errorName || errorLastname || errorEmail || errorAddress || errorOrt || errorPostcode){
            return;
        }
        setAccept(true)
        let res = await fetch("/api/register_member", {
            method: "POST",
            headers: {
            "accept": "application/json",
            'Content-type': 'application/json; charset=UTF-8'
            },
            body: JSON.stringify({
                "token":member_token,
                "url":member_url,
                "name": name,
                "lastname": lastname,
                "email": email,
                "address": address,
                "ort": ort,
                "postcode": postcode
                
            })
        })
        let result = await res.json();
        if (result && res.ok){
            if(result["detail"] === "success"){
                setAccept(true)
                setDone(true)
                // window.location.replace("https://bemuf.org/")
            }
            else{
                alert("Already registered")
            }
        }
    }
    if(done == true){
        return(
            <div className="bg-gradient-to-r from-purple-300 to-blue-200">
            <div className="w-11/12  m-auto py-16 min-h-screen flex items-center justify-center">
                <div className="bg-white shadow overflow-hidden sm:rounded-lg pb-8">
                    <div className="border-t p-5 border-gray-200 text-center pt-8">
                        <div className="flex items-center justify-center">
                            <svg className="w-12 h-12 text-green-500" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                            <h1 className="ml-4 text-lg leading-6 font-medium text-gray-900">
                                Lyckades! 
                            </h1>
                        </div>
                        <p className="mt-3 text-base leading-6 text-gray-500">
                            Du är nu registrerad som medlem i Bemuf.
                        </p>
                        <div className="mt-6">
                            <a href="https://bemuf.org/" className="inline-flex rounded-md ">
                                <button type="button" className="w-full text-sm font-medium text-black transition duration-150 ease-in-out">
                                    Gå till startsidan
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
    else{

   
    if(success === true){
        return (<>
            <div className="bg-gradient-to-r from-purple-300 to-blue-200">
                <div className="w-11/12 m-auto py-16 min-h-screen flex items-center justify-center">
                    <div className="bg-white shadow overflow-hidden sm:rounded-lg pb-8">
                        <div className="border-t p-5 border-gray-200 text-center pt-8">
                            <h1 className="text-4xl font-bold text-purple-400">Registrera dig igen</h1>

                            <div className="mt-5">
                                    <label className="block text-left  uppercase tracking-wide text-gray-700 text-xs font-bold tb-2" htmlFor="grid-first-name">
                                        Namn
                                    </label>
                                    <input
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="grid-first-name"
                                        type="text"
                                        name="name"
                                        placeholder="Namn"
                                        onChange={(e) => onStateChange(e)}
                                        value={name}
                                        style={{ borderColor: errorName ? "red" : "green" }}
                                    />
                                    <label className="block text-left uppercase tracking-wide text-gray-700 text-xs font-bold mt-2" htmlFor="grid-last-name">
                                        Efternamn
                                    </label>
                                    <input
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="grid-last-name"
                                        type="text"
                                        name="lastname"
                                        placeholder="Efternamn"
                                        onChange={(e) => onStateChange(e)}
                                        value={lastname}
                                        style={{ borderColor: errorLastname ? "red" : "green" }}
                                    />
                                    <label className="block text-left uppercase tracking-wide text-gray-700 text-xs font-bold mt-2" htmlFor="grid-email">
                                        Email
                                    </label>
                                    <input
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="grid-email"
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) => onStateChange(e)}
                                        style={{ borderColor: errorEmail ? "red" : "green" }}
                                    />
                                    <label className="block text-left uppercase tracking-wide text-gray-700 text-xs font-bold mt-2" htmlFor="grid-address">
                                        Adress
                                    </label>
                                    <input
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="grid-address"
                                        type="text"
                                        name="address"
                                        placeholder="Adress"
                                        value={address}
                                        onChange={(e) => onStateChange(e)}
                                        style={{ borderColor: errorAddress ? "red" : "green" }}
                                    />
                                    <label className="block text-left uppercase tracking-wide text-gray-700 text-xs font-bold mt-2" htmlFor="grid-ort">
                                        Ort
                                    </label>
                                    <input
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="grid-ort"
                                        type="text"
                                        name="ort"
                                        placeholder="Ort"
                                        value={ort}
                                        onChange={(e) => onStateChange(e)}
                                        style={{ borderColor: errorOrt ? "red" : "green" }}
                                        
                                    />
                                    <label className="block text-left uppercase tracking-wide text-gray-700 text-xs font-bold mt-2" htmlFor="grid-postcode">
                                        Postnummer
                                    </label>
                                    <input
                                        className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        id="grid-postcode"
                                        type="text"
                                        name="postcode"
                                        placeholder="Postnummer"
                                        value={postcode}
                                        onChange={(e) => onStateChange(e)}
                                        style={{ borderColor: errorPostcode ? "red" : "green" }}
                                    />
                            </div>
                            <div className="flex items-start  mt-4">
                                <div className="flex items-center h-5 ">
                                    <input  type="checkbox" className="bg-gray-50 border border-gray-300 focus:ring-3 focus:ring-blue-300 h-4 w-4 rounded"  onChange={() => setAccept(!accept)}/>
                                </div>
                                <div className="text-sm ml-3">
                                    <label className="font-medium text-gray-900">Jag accepterar Bemuf Stadgar och vill åter bli medlem i Bemuf - <a href="https://bemuf.org/regler/" target="_blank" className="text-blue-400">Läs mer</a></label>
                                    <br />
                                    <label className="font-medium text-gray-900">(Prihvatam Statut Bemufa i želim da ostanem član Bemufa  - <a href="https://bemuf.org/regler/" target="_blank" className="text-blue-400">Čitaj više</a>)</label>
                                    <br />
                                    <br />
                                    <button
                                        className="mr-3 disabled:opacity-50 bg-green-400 hover:bg-green-500 text-white font-bold py-3 px-6 rounded-full"
                                        type="button"
                                        onClick={() => spara_member()}
                                        disabled={accept}
                                    >
                                        Anmäl dig igen
                                    </button>
                            </div>
                        </div>

                        </div>
                    </div>
                </div>
            </div>
        </>);
    }
    else if(success === false){
        return (
        <>
            <div className="bg-gradient-to-r from-purple-300 to-blue-200">
            <div className="w-9/12 m-auto py-16 min-h-screen flex items-center justify-center">
            <div className="bg-white shadow overflow-hidden sm:rounded-lg pb-8">
            <div className="border-t border-gray-200 text-center pt-8">
            <h1 className="text-9xl font-bold text-purple-400">404</h1>
            <h1 className="text-6xl font-medium py-8">oops! Page not found</h1>
            <p className="text-2xl pb-8 px-12 font-medium">Oops! The page you are looking for does not exist. It might have been moved or deleted.</p>
            </div>
            </div>
            </div>
            </div>
        </>
        );
    }
    else{
        return (
        <>
        </>
        );
    }
}

}

export default AuthMember;