import React from 'react';
import {Route, Switch,Link } from 'react-router-dom';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import withAuth from './components/auth/withAuth';
import Login from './pages/Login';
import Members from './pages/Members';
import List from './pages/List';
import EditMember from './pages/EditMember';
import Settings from './pages/Settings';
import EditList from './pages/EditList';
import SendMessage from './pages/SendMessage';
import Navbar from './components/navbar/Navbar';
import UserStore from './components/auth/UserStore';
import AuthMember from './pages/AuthMember';
import Group from './pages/Group';

function App(){
  return(
    <>
      {UserStore.isLoggedIn && <Navbar />}
      <Switch>
        <Route exact path="/" component={withAuth(Home)} />
        <Route exact path="/auth/:url/:id" component={AuthMember} />
        <Route exact path="/send_message" component={withAuth(SendMessage)} />
        <Route exact path="/members" component={withAuth(Members)} />
        <Route exact path="/edit_member/:id" component={withAuth(EditMember)} />
        <Route exact path="/list" component={withAuth(List)} />
        <Route exact path="/edit_list/:id" component={withAuth(EditList)} />
        <Route exact path="/settings" component={withAuth(Settings)} />
        <Route exact path="/my_group" component={withAuth(Group)} />

        <Route path="/login" component={Login} />
        <Route path="*" component={NotFound} />
      </Switch>
    </>
  );
}

export default App;
