import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import UserStore from './UserStore';

export default function withAuth(ComponentToProtect) {
  return class extends Component {
    constructor() {
      super();
      this.state = {
        loading: true,
        redirect: false,
      };
    }

    componentDidMount() {
      if (UserStore.user != null){
        this.setState({ loading : false});
        return;
      }

      fetch('/api/isLoggedIn')
        .then(res => {
          if (res.status === 200) {
            UserStore.setIsLoggedIn( true );
            res.json().then(data =>{
              UserStore.setUser(data);
              this.setState({ loading: false });
            })
          } else {
            UserStore.removeAll();
            const error = new Error(res.error);
            throw error;
          }
        })
        .catch(err => {
          this.setState({ loading: false, redirect: true });
        });
    }


    render() {
      const { loading, redirect } = this.state;
      if (loading) {
        return (<div>Loading....</div>);
      }
      if (redirect) {
        return <Redirect to="/login" />;
      }
      return <ComponentToProtect {...this.props} />;
    }
  }
}