import React , { useEffect } from "react"
import UserStore from '../components/auth/UserStore'

import PostCard from '../components/PostCard'

class Home extends React.Component{    
    constructor() {
        super();
        this.state = {
            user: null,
            alla_meddelande: []
        }; 
    }
    
    componentDidMount() { 
        let user =UserStore.getUser();
        console.log(user);
        this.setState({user: user});

        fetch('/api/all_messages', {method: "get"})
            .then(res => {
            if (res.status === 200) {
                res.json().then(data =>{
                this.setState({ alla_meddelande: data})
                })               
            } else {
                if (res.statusText == "Unauthorized"){
                    let res = fetch('/api/logout',{method: 'get'});
                    UserStore.removeAll();
                    window.location.replace("/login");
                    return;
                }
                const error = new Error(res.error);
                throw error;
            }
            })
            .catch(err => {
                console.error(err);
                alert("Server ERROR")
            });
    }
 
    render(){
        if (this.state.user == null) {
            return "Loading...";
        }
        return(
            <div>
                <PostCard 
                    alla_meddelande ={this.state.alla_meddelande}
                />

            </div>
        );
    }
}

export default Home;