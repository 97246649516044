import "./tailwind-components.css";
import * as React from "react";
import {Member, GroupMembers} from '../interfaces';


interface SelectTableComponentProps {
  all_members: Member[];
  checkedUsers: number[];
  setMembersOfGroup: (members: number[]) => void;
}


const SelectTableComponent: React.FC<SelectTableComponentProps> = (props) => {
  const [filter, setFilter] = React.useState("");
  console.log(">:/", props)
  const index = React.useRef(0);
  const allCheckedCheckbox = React.useRef<HTMLInputElement>(null);
  index.current += 1;
  
  React.useEffect(() => {
    if (allCheckedCheckbox && allCheckedCheckbox.current) {
      if (props.checkedUsers.length >= props.all_members.length) {
        allCheckedCheckbox.current.checked = true;
      } else {
        allCheckedCheckbox.current.checked = false;
      }
    }
  });

  const filteredMembers = React.useMemo(() => {
    return props.all_members.filter((member) =>
      `${member.name} ${member.lastname}`
        .toLowerCase()
        .trim()
        .includes(filter.toLowerCase())
    );
  }, [filter]);

  function TableHeader(props) {
    return (
      <th
        scope="col"
        className="px-10 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
      >
        {props.children}
      </th>
    );
  }

  function TableData(props) {
    return (
      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
        {props.children}
      </td>
    );
  }

  function SearchIcon() {
    return (
      <svg viewBox="0 0 24 24" className="h-4 w-4 fill-current text-gray-500">
        <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"></path>
      </svg>
    );
  }

  if (props.all_members.length <= 0) {
    return (
      <div className="container mx-auto">
        <p className="mt-10">No members.</p>
      </div>
    );
  }
  return (
    <div className="container mx-auto p-2">
      <div className="my-2 flex sm:flex-row flex-col">
        <div className="block relative mb-2">
          <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
            <SearchIcon />
          </span>
          <input
            placeholder="Search"
            onChange={(e) => setFilter(e.currentTarget.value)}
            className="search-field"
          />
        </div>
      </div>
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table
              className="min-w-full divide-y divide-gray-200"
              id="all_members_table"
            >
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col">
                    <input
                    ref={allCheckedCheckbox}
                    onChange={e => {
                      if (e.currentTarget.checked) {
                        props.setMembersOfGroup(props.all_members.map(m => m.id));
                      } else {
                        props.setMembersOfGroup([]);
                      }
                    }}
                    type="checkbox" />
                  </th>
                  <TableHeader>Namn</TableHeader>
                  <TableHeader>Telefonnummer</TableHeader>
                  <TableHeader>Adress</TableHeader>
                  <TableHeader>Ort</TableHeader>
                  <TableHeader>Postnummer</TableHeader>
                  <TableHeader>Stad</TableHeader>
                </tr>
              </thead>
              <tbody key={index.current} className="bg-white divide-y divide-gray-200">
                {filteredMembers.map((member) => (
                  <tr key={member.id}>
                  <td className="flex p-6 justify-center">
                    <input
                      defaultChecked={!!props.checkedUsers.find(m => member.id === m)}
                      onChange={(e) =>
                        {props.setMembersOfGroup(
                          (e.currentTarget.checked ? [member.id] : []).concat(
                            props.checkedUsers.filter(m => m !== member.id)
                          )
                        )
                      }}
                      type="checkbox"
                    />
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="ml-4">
                        <div className="text-sm font-medium text-gray-900">
                          {member.name} {member.lastname}
                        </div>
                        <div className="text-sm text-gray-500">
                          {member.birthday}
                        </div>
                      </div>
                    </div>
                  </td>
                  <TableData>{member.number}</TableData>
                  <TableData>{member.address}</TableData>
                  <TableData>{member.ort}</TableData>
                  <TableData>{member.postcode}</TableData>
                  <TableData>{member.city}</TableData>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectTableComponent;
