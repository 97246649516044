class UserStore{
    constructor(){
        this.isLoggedIn = false
        this.user = null
        if (localStorage.getItem('isLoggedIn')) {
            this.isLoggedIn = JSON.parse(localStorage.getItem('isLoggedIn'));
        }
        if (localStorage.getItem('user')) {
            this.user = JSON.parse(localStorage.getItem('user'));
        }
    }

    checkIfLoggedIn(){
        return this.isLoggedIn;
    }

    setIsLoggedIn( value ){
        this.isLoggedIn = value;
        localStorage.setItem('isLoggedIn', this.isLoggedIn);
    }

    setUser( value ){
        this.user = JSON.stringify(value);
        localStorage.setItem('user', JSON.stringify(this.user));
    }

    getUser(){
        if (this.user == null){
            if (localStorage.getItem('user') != undefined) {
                this.user = JSON.parse(localStorage.getItem('user'));
            }
        }       
        return JSON.parse(this.user);
    }

    removeAll(){
        localStorage.clear();
    }

}

export default new UserStore();