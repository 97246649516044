import user_png from "../Images/default_user.png"


export default function PostCard(props)  {
    if (props.alla_meddelande.length == 0){
        return(
            <div class="container mx-auto">
                <p class="mt-10">No messages have been sent!</p>
            </div>
        );
    }
  return (
    <div class="container mx-auto">
    {props.alla_meddelande.map((meddelande) => (
        <div key={meddelande.id} class="flex bg-white shadow-lg rounded-lg mx-4 md:mx-auto mt-10  md:max-w-full ">
            <div class="flex items-start px-4 py-6">
                <img class="w-12 h-12 rounded-full object-cover mr-4 shadow" src={user_png} alt="avatar" />
                <div class="">
                    <div class="flex items-center justify-between">
                        <h2 class="text-lg font-semibold text-gray-900 -mt-1">{meddelande.full_user.name} </h2>
                    </div>
                    <p class="text-gray-700">{meddelande.date.slice(0, 10)} | {meddelande.date.slice(11, 16)}</p>
                    <p class="mt-2 text-gray-700 text-sm">Titel:</p>
                    <p class=" text-xl">
                    {meddelande.title}
                    </p>
                    <p class="mt-2 text-gray-700 text-sm">Meddelande:</p>
                    <p class="text-xl" style={{whiteSpace: "pre-wrap"}}>
                    {meddelande.message}
                    </p>
                    <p class="mt-2 text-gray-700 text-sm">Pris:
                    <span class=" text-black-900 text-sm "> <b>{meddelande.price} kr</b></span>
                    </p>
                    <p class="mt-2 text-gray-700 text-sm">Hur många SMS skickades:
                    <span class=" text-black-900 text-sm"> <b>{meddelande.message_count}</b></span>
                    </p>
                </div>
            </div>
        </div>
    ))}
  </div>
  )
}