import * as React from "react";


const SendForm = (props) => {
  return (<>
  <div className="container mx-auto p-6 ">
        <form className="w-full">
          <div className="flex flex-wrap  -mx-3 mb-2 ">

              <div className="w-full px-3 md:w-1/3 mb-6 md:mb-0">
                  <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-state">
                    Till vem
                  </label>
                  <div className="relative">
                    <select className="block appearance-none w-full bg-gray-50 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="grid-state"
                      onChange={e => props.changeList(e.target.value)}
                    >
                    {props.lists.map((element) => (
                      <option key={element.id} value={element.id}>{element.name}</option>
                    ))}
                    </select>
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                      <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/></svg>
                    </div>
                </div>
              </div>
          </div>

        <div className="flex flex-wrap -mx-3 mb-6">

            <div className="w-full  md:w-1/3 mt-3 px-3 mb-6 md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-first-name">
                  Titel
                </label>
                <input
                  className="bg-gray-50 focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
                  type="text"
                  maxLength={11}
                  placeholder="Titel"
                  autoComplete="off"
                  onChange={(e) => props.setTitel(e.target.value)}
                />          
            </div>
        </div>

        <div className="flex flex-wrap -mx-3 mb-6 ">

          <div className="w-full  px-3 ">
            <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2 " htmlFor="grid-last-name">
              Meddelande
            </label>
            <textarea className="bg-gray-50 w-full h-40 px-3 py-2 text-base text-gray-700 placeholder-gray-600 border rounded-lg focus:shadow-outline" 
              placeholder="Meddelande..."
              onChange={(e) => props.setMessage(e.target.value)}
            ></textarea>          
            <span>Förnamn: {"{namn}"}</span>
            <br />
            <span>URL för att anmäla sig: {"{url}"}</span>
            <br />
            <span>Note: be careful when you use  {"{url}"}</span>

          </div>
        </div>

      <div className="flex flex-wrap -mx-3 mb-6 ">
   
        <div className="w-full px-3 ">
          <span>Totala kostnad: {props.cost} kr</span>
          <br />
          <br />
          <button type="button" className="mr-3 bg-transparent bg-green-500 text-white hover:bg-green-600  font-semibold hover:text-white py-2 px-4 border border-green-500 hover:border-transparent rounded"
            onClick={() => props.sendMessage()}
          >
            Skicka
          </button>      
          <button type="button" className="bg-transparent bg-yellow-500 text-white hover:bg-yellow-600  font-semibold hover:text-white py-2 px-4 border border-yellow-500 hover:border-transparent rounded"
            onClick={() => props.testMessage()}
          >
            Testa
          </button>   
        </div>
      </div>

      </form>

  </div>
  </>);
};

export default SendForm;
