import { METHODS } from "http";
import React from "react";



export default function AddMembers()  {

    let all_citys = [
        "Borås",
        "Gislaved",
        "Göteborg",
        "Halmstad",
        "Helsingborg",
        "Jönköping",
        "Landskrona",
        "Linköping",
        "Malmö",
        "Norrköping",
        "Skövde",
        "Stockholm",
        "Trelleborg",
        "Trollhättan",
        "Västerås",
        "Växjö",
    ]

    function add_members(){
        var form = document.getElementById("add_user");
        const data = new FormData(form);
        const value = Object.fromEntries(data.entries());
        let number = data.get('number');
        let birthday = data.get('birthday');
        if(birthday.length != 10){
            alert("Wrong birthday format, Right format is åååå-mm-dd")
        }
        if((number.length != 12) && (number.slice(0, 3) == "+46")){
            alert("Number is wrong")
            return;
        }

        fetch("/api/add_member",{
            method: "post",
            headers:{
              'Content-type': 'application/json; charset=UTF-8' 
            },
            body: JSON.stringify(value)
        })
        .then(res => {
            if (res.status === 200){
                console.log("Added the member")
                window.location.reload()
            }
            else{
                
                alert("Cant add member, try agin!")
            }
          })
        .catch(err => {
            alert(err)
        });
        console.log(JSON.stringify(value));
        setShowModal(false)
    }
    const sync_google = async() =>{
        console.log("sync_google")
        let res = await fetch("/api/sync_google", {
            method: "GET", 
            headers: {
            "accept": "application/json",
            },
        })
        let result = await res.json();
        if (result && res.ok){
            if (result["detail"] == "success"){
                alert("Sync done")
                window.location.reload()
            }
            else{
                alert("Cant sync right now, try agin later")
                window.location.reload()
            }
        }
        else{
            window.location.replace("/")
        }
    }
    const [showModal, setShowModal] = React.useState(false);
    return (
        <div className="container mx-auto p-2 ">
            <button className="w-auto px-6 bg-green-400 hover:bg-green-500 text-white font-bold  px-4 rounded-full mt-6 inline-flex items-center "
                        type="button"
                        onClick={() => setShowModal(true)}
                    >
                    <svg className="svg-circleplus w-12 p-2" style={{stroke:"#FFF", strokeWidth: "1"}} viewBox="0 0 100 100">
                        <circle cx="50" cy="50" r="35" fill="none" stroke-width="7.5"></circle>
                        <line x1="32.5" y1="50" x2="67.5" y2="50" stroke-width="7.5"></line>
                        <line x1="50" y1="32.5" x2="50" y2="67.5" stroke-width="7.5"></line>
                    </svg>
                <span>Add member</span>
            </button>
            <br />
            <button className="py-3 px-7 w-auto bg-yellow-400 hover:bg-yellow-500 text-white font-bold  px-4 rounded-full inline-flex items-center "
                        type="button"
                        onClick={() => sync_google()}
                    >

               Sync Google Sheet
            </button>
            {showModal ? (
            <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none p-2 pt-40 md:pt-0"
            >
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-3xl font-semibold">
                        Add member
                    </h3>
                    <button
                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => setShowModal(false)}
                    >
                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                        </span>
                    </button>
                    </div>
                    {/*body*/}
                    <form id="add_user">
                    <div className="relative p-6 flex-auto">
                        
                        <div className=" overflow-hidden sm:rounded-md">
                            <div className="px-4 py-5 bg-white sm:p-6">
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                    Namn
                                </label>
                                <input
                                    type="text"
                                    name="name"
                                    id="first-name"
                                    autocomplete="off"
                                    className="mt-1 p-2 border-solid border-2 border-light-gray-500 border-solid border-2 border-light-gray-500 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="last-name" className="block text-sm font-medium text-gray-700">
                                    Efternamn
                                </label>
                                <input
                                    type="text"
                                    name="lastname"
                                    id="last-name"
                                    autocomplete="off"
                                    className="mt-1 p-2 border-solid border-2 border-light-gray-500 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                </div>

                                <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                                <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                                    Föddelsedag
                                </label>
                                <input
                                    type="date"
                                    name="birthday"
                                    id="birthday"
                                    placeholder="åååå-mm-dd"
                                    autocomplete="off"
                                    className="mt-1 p-2 border-solid border-2 border-light-gray-500 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                </div>
                                <div className="col-span-6 sm:col-span-3 lg:col-span-3">
                                    <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                        Kön:
                                    </label>
                                    <select name="gender" id="gender" className="form-select block w-full mt-1">
                                        <option value="" >----Välj kön---</option>
                                        <option value="Female">Kvinna</option>
                                        <option value="Male">Man</option>
        
                                    </select>
                                </div>
 
                                <div className="col-span-6">
                                <label htmlFor="number" className="block text-sm font-medium text-gray-700">
                                    Nummer
                                </label>
                                <input
                                    type="text"
                                    name="number"
                                    id="number"
                                    maxLength="12"
                                    defaultValue="+46"
                                    placeholder="+46"
                                    autocomplete="off"
                                    className="mt-1 p-2 border-solid border-2 border-light-gray-500 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                </div>
              
                                <div className="col-span-6">
                                <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    autocomplete="off"
                                    className="mt-1 p-2 border-solid border-2 border-light-gray-500 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                </div>

                                <div className="col-span-6">
                                <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                                    Adress
                                </label>
                                <input
                                    type="text"
                                    name="address"
                                    id="street-address"
                                    className="mt-1 p-2 border-solid border-2 border-light-gray-500 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                </div>

                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                    <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                        Stad:
                                    </label>
                                    <select name="city" id="city" className="form-select block w-full mt-1">
                                        <option value="">----Välj en stad----</option>

                                        {all_citys.map((city) => (
                                            <option value={city}>{city}</option>
                                        ))}

                                    </select>
                                </div>

                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                <label htmlFor="region" className="block text-sm font-medium text-gray-700">
                                    Ort
                                </label>
                                <input
                                    type="text"
                                    name="ort"
                                    id="ort"
                                    className="mt-1 p-2 border-solid border-2 border-light-gray-500 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                </div>

                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                <label htmlFor="postcode" className="block text-sm font-medium text-gray-700">
                                Postnummer
                                </label>
                                <input
                                    type="text"
                                    name="postcode"
                                    id="postcode"
                                    className="mt-1 p-2 border-solid border-2 border-light-gray-500 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    </form>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowModal(false)}
                    >
                        Close
                    </button>
                    <button
                        className="bg-green-400 hover:bg-green-500 text-white font-bold py-2 px-6 rounded-full"
                        type="button"
                        onClick={() => add_members()}
                    >
                        Save
                    </button>
                    </div>
                </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
         ) : null}
        </div>
        
    );
}