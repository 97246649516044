import React from 'react'
import { LockClosedIcon } from '@heroicons/react/solid'
import UserStore from '../auth/UserStore';
import sms_logo from "../../Images/sms_logo.jpg"
export default class LoginForm extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      error_span: "",
      error_times: 1
    };
  }



  async doLogin(){
    const delay = ms => new Promise(res => setTimeout(res, ms));
    document.getElementById("login-button").disabled = true;

    try {
      let email = document.getElementById("email-address").value.toString();
      let password = document.getElementById("password").value.toString();
      if (email.length === 0){
        document.getElementById("login-button").disabled = false;
        return;}
      if (password.length === 0){
        document.getElementById("login-button").disabled = false;        
        return;}
      if(password.length <= 6){
        this.setState({error_span: "Password must contain at least 6 characters"});
        document.getElementById("login-button").disabled = false;
        return;
      }

      let form_data = new URLSearchParams({

        "username": email,
        "password": password

      }).toString();
      let res = await fetch("/api/login",{
        method: "post",
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: form_data
  
      });
      
      let result = await res.json();

      if (result && res.status === 200){
        UserStore.setIsLoggedIn(true);
        window.location.replace("/");
      }
      else if ( result && res.status === 401){
        if (this.state.error_times >= 4){
          this.setState({error_span: "Wait 3 seconds before retrying..."});

          document.getElementById("login-button").disabled = true;
          document.getElementById("login-button").style.opacity = "0.3";
          await delay(3000);
          document.getElementById("login-button").disabled = false;
          document.getElementById("login-button").style.opacity = "1";


        }
        this.setState({error_span: "Invalid credentials "+ this.state.error_times});
        this.setState(prevState => {
          return {error_times: prevState.error_times + 1}
        })
        document.getElementById("login-button").disabled = false;

      }
      
    }
    catch(e){
      console.error(e);
      alert('Error logging in please try again later');
      document.getElementById("login-button").disabled = false;

    }
  }
  render(){
  return (
    <>
      <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8 mt-28">
        <div className="max-w-md w-full space-y-8">
          <div>
            <img
              className="mx-auto h-12 w-auto"
              src={sms_logo}
              alt="Logo"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Bemuf SMS app</h2>
            
          </div>
          <div className="mt-8 space-y-6" id="login-form">
            <div className="rounded-md shadow-sm -space-y-px">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email-address"
                  name="username"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Email address"
                />
              </div>
              <div>
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  minLength="5"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                />
              </div>
            </div>
            <span className="text-red-600">{this.state.error_span}</span>


 
            <div>
              <button
                id="login-button"
                type="button"
                onClick={() => this.doLogin()}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                  <LockClosedIcon className="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" aria-hidden="true" />
                </span>
                Logga in
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )};
}
