
function search(event) {
    let val = event.target.value;
    let filter = val.toUpperCase().trim();
    let table = document.getElementById("all_members_table");
    let tr = table.getElementsByTagName("tr");

    for (let i = 0; i < tr.length; i++) {
        let td = tr[i].getElementsByTagName("td")[0];
        if (td) {
            let txtValue = td.textContent || td.innerText;
            if (txtValue.toUpperCase().indexOf(filter) > -1) {
              tr[i].style.display = "";
            } else {
              tr[i].style.display = "none";
            }
        }       
    }
}


export default function AllaMembers(props)  {

    function edit_member( member_id ){
        console.log(member_id)
    }

    async function delete_user( user_id ){
        if (window.confirm('Should we delete member '+ user_id)) {
            let res = await fetch("/api/delete_member",{
                method: "DELETE",
                headers: {
                    'Content-type': 'application/json; charset=UTF-8' 
                },
                body: JSON.stringify({
                    "id" : user_id
                })
            });
            
            if (res.status === 200){
                console.log("Removed the member")
                window.location.reload()
            }
            else{
                alert("Cant remove member, try agin!")
            }
        }
    }

    if (props.all_members.length == 0){
        return(
            <div class="container mx-auto">
                <p class="mt-10">No members.</p>
            </div>
        );
    }
  return (
    <div className="container mx-auto  p-2">

        <div class="my-2 flex sm:flex-row flex-col">
            <div class="block relative mb-2">
                <span class="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                    <svg viewBox="0 0 24 24" class="h-4 w-4 fill-current text-gray-500">
                        <path
                            d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z">
                        </path>
                    </svg>
                </span>
                <input placeholder="Search" onChange={ (event) => search(event) }
                    class="appearance-none rounded-r rounded-l sm:rounded-l-none border border-gray-400 border-b block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none" />
            </div>
        </div>
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200" id="all_members_table">
                    <thead className="bg-gray-50">
                        <tr>
                            <th 
                                scope="col"
                                className=" px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                            Namn 
                            </th>
                            <th
                                scope="col"
                                className=" px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                            Telefonnummer
                            </th>
                            <th
                                scope="col"
                                className=" px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                            Adress
                            </th>
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                            Ort
                            </th>              
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                            Postnummer
                            </th>     
                            <th
                                scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                            >
                            Stad
                            </th>              
                            <th scope="col">
                            </th>
                            <th scope="col">
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {props.all_members.map((member) => (
                            <tr key={member.id}>
                                <td className="px-6 py-4 whitespace-nowrap">
                                    <div className="flex items-center">
                                        <div className="ml-4">
                                            <div className="text-sm font-medium text-gray-900">{member.name} {member.lastname}</div>
                                            <div className="text-sm text-gray-500">{member.birthday}</div>
                                        </div>
                                    </div>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{member.number}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{member.address}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{member.ort}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{member.postcode}</td>
                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{member.city}</td>

                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    
                                    <a href={"/edit_member/"+ member.id} className="text-indigo-600 hover:text-indigo-900 ">
                                        Edit
                                    </a>
                                </td>
                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                    <button class="m-0 inline-flex items-center justify-center w-10 h-10 text-indigo-100 transition-colors duration-150 rounded-lg focus:shadow-outline hover:bg-red-100"
                                        onClick={() => delete_user(member.id)}
                                    >
                                        <svg class="m-2" style={{stroke:"#FF0000", strokeWidth: "1"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fill="none" d="M12.71,7.291c-0.15-0.15-0.393-0.15-0.542,0L10,9.458L7.833,7.291c-0.15-0.15-0.392-0.15-0.542,0c-0.149,0.149-0.149,0.392,0,0.541L9.458,10l-2.168,2.167c-0.149,0.15-0.149,0.393,0,0.542c0.15,0.149,0.392,0.149,0.542,0L10,10.542l2.168,2.167c0.149,0.149,0.392,0.149,0.542,0c0.148-0.149,0.148-0.392,0-0.542L10.542,10l2.168-2.168C12.858,7.683,12.858,7.44,12.71,7.291z M10,1.188c-4.867,0-8.812,3.946-8.812,8.812c0,4.867,3.945,8.812,8.812,8.812s8.812-3.945,8.812-8.812C18.812,5.133,14.867,1.188,10,1.188z M10,18.046c-4.444,0-8.046-3.603-8.046-8.046c0-4.444,3.603-8.046,8.046-8.046c4.443,0,8.046,3.602,8.046,8.046C18.046,14.443,14.443,18.046,10,18.046z"></path></svg>
                                    </button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
  )
}