import { METHODS } from "http";
import React from "react";



export default function AddUser()  {


    function add_members(){
        var form = document.getElementById("add_user");
        const data = new FormData(form);
        const value = Object.fromEntries(data.entries());
        fetch("/api/add_user",{
            method: "post",
            headers:{
              'Content-type': 'application/json; charset=UTF-8' 
            },
            body: JSON.stringify(value)
        })
        .then(res => {
            if (res.status === 200){
                window.location.reload()
            }
            else{
                
                alert("Cant add member, try agin!")
            }
          })
        .catch(err => {
            alert(err)
        });
        console.log(JSON.stringify(value));
        setShowModal(false)
    }
    const [showModal, setShowModal] = React.useState(false);

    
    return (
        <div className="container mx-auto p-2 ">
            <button className="w-auto px-6 bg-green-400 hover:bg-green-500 text-white font-bold  px-4 rounded-full mt-6 inline-flex items-center "
                        type="button"
                        onClick={() => setShowModal(true)}
                    >
                    <svg className="svg-circleplus w-12 p-2" style={{stroke:"#FFF", strokeWidth: "1"}} viewBox="0 0 100 100">
                        <circle cx="50" cy="50" r="35" fill="none" stroke-width="7.5"></circle>
                        <line x1="32.5" y1="50" x2="67.5" y2="50" stroke-width="7.5"></line>
                        <line x1="50" y1="32.5" x2="50" y2="67.5" stroke-width="7.5"></line>
                    </svg>
                <span>Add User</span>
            </button>
          
            {showModal ? (
            <>
            <div
                className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none p-2 pt-40 md:pt-0"
            >
                <div className="relative w-auto my-6 mx-auto max-w-3xl">
                {/*content*/}
                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                    {/*header*/}
                    <div className="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t">
                    <h3 className="text-3xl font-semibold">
                        Add member
                    </h3>
                    <button
                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => setShowModal(false)}
                    >
                        <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                        </span>
                    </button>
                    </div>
                    {/*body*/}
                    <form id="add_user">
                    <div className="relative p-6 flex-auto">
                        
                        <div className=" overflow-hidden sm:rounded-md">
                            <div className="px-4 py-5 bg-white sm:p-6">
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6 sm:col-span-3">
                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                    Name and Lastname
                                </label>
                                <input
                                    type="text"
                                    name="full_name"
                                    id="first-name"
                                    autocomplete="off"
                                    className="mt-1 p-2 border-solid border-2 border-light-gray-500 border-solid border-2 border-light-gray-500 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                </div>

                                <div className="col-span-6">
                                <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    autocomplete="off"
                                    className="mt-1 p-2 border-solid border-2 border-light-gray-500 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                </div>

                       
                            </div>
                        </div>
                    </div>
                    </div>
                    </form>
                    {/*footer*/}
                    <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                    <button
                        className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowModal(false)}
                    >
                        Close
                    </button>
                    <button
                        className="bg-green-400 hover:bg-green-500 text-white font-bold py-2 px-6 rounded-full"
                        type="button"
                        onClick={() => add_members()}
                    >
                        Save
                    </button>
                    </div>
                </div>
                </div>
            </div>
            <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
            </>
         ) : null}
        </div>
        
    );
    
}