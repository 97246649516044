import React , { useEffect } from "react"
import UserStore from '../components/auth/UserStore'


function edit_user(user_id, admin){
    var form = document.getElementById("edit_user");
    const data = new FormData(form);
    const value = Object.fromEntries(data.entries());
    value.user_id = user_id;
    value.admin = admin;
    let password = data.get('password');
    if(password.length == 0){
        value.password = null;
    }
    fetch("/api/edit_user",{
        method: "post",
        headers:{
          'Content-type': 'application/json; charset=UTF-8' 
        },
        body: JSON.stringify(value)
    })
    .then(res => {
        if (res.status === 200){
            console.log("Edited the user")
            window.location.reload()
        }
        else{
            alert("Cant edit user right now, try agin!")
        }
      })
    .catch(err => {
        alert(err)
    });
    console.log(JSON.stringify(value));
}


class Settings extends React.Component{    
    
    constructor() {
        super();
        this.state = {
            user: null,
            user_info: []
        }; 
    }
  
    componentDidMount() { 
        let user =UserStore.getUser();
        this.setState({user: user});
        fetch("/api/isLoggedIn", {method: "get"})
        .then(res => {
        if (res.status === 200) {
            res.json().then(data =>{
            this.setState({ user_info: data})
            console.log(data)
            })               
        } else {
            if (res.statusText == "Unauthorized"){
                let res = fetch('/api/logout',{method: 'get'});
                UserStore.removeAll();
                window.location.replace("/login");
                return;
            }
            const error = new Error(res.error);
            throw error;
        }
        })
        .catch(err => {
            alert("There has been a error, try agin!")
        });

    }
 
    render(){
        if (this.state.user == null) {
            return "Loading...";
        }
        if (this.state.user_info == null){
            return (
            <>

                <div className="container mx-auto  p-2 mt-5">
                    <h1>There has been a error with the server!</h1>
                </div>
            </>
        )
        }
        return(
            <>

                <div className="container mx-auto p-2">
                <form id="edit_user">
                    <div className="relative p-10 flex-auto">
                        
                        <div className=" overflow-hidden sm:rounded-md">
                            <div className="px-4 py-5 bg-white sm:p-6">
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6">
                                <label htmlFor="first-name" className="block text-sm font-medium text-gray-700">
                                    Namn
                                </label>
                                <input
                                    type="text"
                                    name="full_name"
                                    id="full_name"
                                    defaultValue={this.state.user_info.name}
                                    placeholder="Namn och efternamn"
                                    autocomplete="off"
                                    className="mt-1 p-2 border-solid border-2 border-light-gray-500 border-solid border-2 border-light-gray-500 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                </div>
                                <div className="col-span-6">
                                <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    defaultValue={this.state.user_info.email}
                                    placeholder="email"
                                    autocomplete="off"
                                    className="mt-1 p-2 border-solid border-2 border-light-gray-500 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                />
                                </div>
                                <div className="col-span-6">
                                <label htmlFor="email-address" className="block text-sm font-medium text-gray-700">
                                    Lössenord
                                </label>
                                <input
                                    type="text"
                                    name="password"
                                    id="password"
                                    placeholder="************"
                                    autocomplete="off"
                                    className="mt-1 p-2 border-solid border-2 border-light-gray-500 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                                    />
                                    <span className="block text-sm font-medium text-gray-700">Leave empty if you dont want to change the password</span>
                                </div>

    
                            </div>
                        </div>
                    </div>
                    </div>
                    </form>
                    <div className="flex items-center justify-end p-6 border-solid border-blueGray-200 rounded-b">
                        <button
                            className="bg-green-400 hover:bg-green-500 text-white font-bold py-2 px-6 rounded-full"
                            type="button"
                            onClick={() => edit_user(this.state.user_info.id, this.state.user_info.admin)}
                        >
                            Save
                        </button>
                    </div>
                </div>
                </>

        );
    }
}

export default Settings;