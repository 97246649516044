import React, { useState, useEffect } from 'react';
import SendForm from "../components/form/SendForm";
import TestMessage from "../components/modals/TestMessage";

async function calcCostApi(message){
    let res = await fetch("/api/message_cost", {
        method: "POST", 
        headers: {
        "accept": "application/json",
        'Content-type': 'application/json; charset=UTF-8' 
        },
        body: JSON.stringify({"message":message})
    })
    let result = await res.json();
    if (result && res.ok){
        if (result["estimated_cost"] != null){
            let cost = result["estimated_cost"]
            return cost;
        }
        else{
            return 0;
        }
    }
    else{
        return 0;
    }
}

const SendMessage = (props) => {

    const [receiver, setReceiver] = useState([]);
    const [listid, setListid] = useState(0);
    const [many, setMany] = useState(0);
    const [titel, setTitel] = useState("");
    const [message, setMessage] = useState("");
    const [cost, setCost] = useState(0.0);
    const [open, setOpen] = useState(false)

    useEffect(async () => {

        let res_all_members = await await fetch("/api/all_members", {method: "GET", headers: {"accept": "application/json"}})
        let result2 = await res_all_members.json();

        if (result2 && res_all_members.ok){
            let data = {name: 'Alla medlemmar', id: 0, members_in_list: result2.length}
            setMany(result2.length)
            setReceiver(prevArray => [...prevArray, data])
        }
        else{
            alert("There has been a error, please try agin")
            window.location.replace("/")
        }
            
        let res = await fetch("/api/lists", {method: "GET", headers: {"accept": "application/json"}})
        let result = await res.json();
        if (result && res.ok){
            for(let i = 0; result.length > i; i ++){
                result[i].members_in_list = result[i].members_in_list.length
                setReceiver(prevArray => [...prevArray, result[i]])
            }
        }
        else{
            alert("There has been a error, please try agin")
            window.location.replace("/")
        }

    }, []);
    function hasWhiteSpace(s) {
        return s.indexOf(' ') >= 0;
      }

    const  sendMessage = async () => {
        if (titel == "" || message == ""){
            alert("Please fill in all the fields")
            return;
        }
        else if (hasWhiteSpace(titel)){
            alert("Title can not contain spaces")
            return;
        }
        let reg_test = titel.match(/[^a-zA-Z0-9]+/g);
        if (reg_test) {
            alert("Title can not contain special characters")
            return;
        }


        let res4 = await fetch("/api/saldo", {method: "GET", headers: {"accept": "application/json"}})
        let saldo = await res4.json();
        if (saldo && res4.ok){
          if (saldo["saldo"] != null){
            let current_saldo = saldo["saldo"]
            let final_cost = current_saldo - cost;
            if (final_cost < 0){
                alert("You don't have enough money")
                return;
            }
          }
        }

        if (window.confirm('Are you sure you want to send the SMS')) {
            let res = await fetch("/api/send_message", {
                method: "POST", 
                headers: {
                "accept": "application/json",
                'Content-type': 'application/json; charset=UTF-8' 
                },
                body: JSON.stringify(
                {
                    "listid":listid,
                    "titel":titel,
                    "message":message,
                    "cost": cost
                }
                )
            })
            let result = await res.json();
            if (result && res.ok){
                if (result["detail"] == "success"){
                    alert("SMS sent!")
                    window.location.replace("/")
                }
                else{
                    alert("There has been a error, try agin!")
                }
            }
            else{
                alert("COPY the message and refresh site")
            }

        }
    }

    const  testMessage = () => {
        setOpen(true)
    }

    const calcCost = async (m) => {
        let prev_message = message
        setMessage(m)
        if ((m.length - 1) % 60 == 0){
            let cost = await calcCostApi(m)
            setCost((cost * many).toFixed(2))
        }
        else if (m.length - 1 > prev_message.length){
            let cost = await calcCostApi(m)
            setCost((cost * many).toFixed(2))
        }
        else if (m.length + 1 < prev_message.length){
            let cost = await calcCostApi(m)
            setCost((cost * many).toFixed(2))
        }

    } 

    const changeList = async (id) =>{
        setListid(id)
        for(let r in receiver){
            if (receiver[r].id == id){
                setMany(receiver[r].members_in_list)
                let cost = await calcCostApi(message)
                setCost((cost * receiver[r].members_in_list).toFixed(2))
            }
        }
    }
    const sendTestMessage = async (number) =>{

        let res = await fetch("/api/test_message", {
            method: "POST", 
            headers: {
            "accept": "application/json",
            'Content-type': 'application/json; charset=UTF-8' 
            },
            body: JSON.stringify(
            {
                "titel":titel,
                "message":message,
                "number":number
            }
            )
        })
        let result = await res.json();
        if (result && res.ok){
            if (result["detail"] == "success"){
                alert("SMS sent!")
            }
            else{
                alert("There has been a error, try agin!")
            }
        }
        else{
            alert("COPY the message and refresh site")
        }
        setOpen(false)
    }

    if (receiver.length > 0){
        return <>
        <SendForm 
            lists={receiver}
            changeList={(e) => changeList(e)}
            sendMessage ={ sendMessage.bind(this)}
            testMessage={testMessage.bind(this)}
            cost={cost}

            setTitel={(e) => setTitel(e)}
            setMessage={(e) => calcCost(e)}

        />
        <TestMessage 
            open={open}
            setOpen={(e) => setOpen(e)}
            sendTestMessage={(e) => sendTestMessage(e)}
        />
        </> 
    }
    else{
        return <>
            
        </>;
    }
}
 
export default SendMessage;