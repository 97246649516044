import React , { useEffect } from "react"
import UserStore from '../components/auth/UserStore'

import AllLists from "../components/AllLists";
import AddList from "../components/form/AddList";



class List extends React.Component{    


    constructor() {
        super();
        this.state = {
            user: null,
            all_lists: []
        }; 
    }
    
    componentDidMount() { 
        let user =UserStore.getUser();
        this.setState({user: user});

        fetch('/api/lists', {method: "get"})
            .then(res => {
            if (res.status === 200) {
                res.json().then(data =>{
                this.setState({ all_lists: data})
                console.log(data);
                })               
            } else {
                if (res.statusText == "Unauthorized"){
                    let res = fetch('/api/logout',{method: 'get'});
                    UserStore.removeAll();
                    window.location.replace("/login");
                    return;
                }
                const error = new Error(res.error);
                throw error;
            }
            })
            .catch(err => {
                alert("There has been a error, try agin!")
            });
    }
 
    render(){
        if (this.state.user == null) {
            return "Loading...";
        }
        return(
            <div>

                <AddList />
                <AllLists all_lists={this.state.all_lists} />
            </div>
        );
    }
}

export default List;