import React , { useEffect } from "react"
import UserStore from '../components/auth/UserStore'

import SelectTableComponent from "../components/SelectTableComponent";
import {RouteComponentProps} from 'react-router-dom';
import {Member, GroupMembers, User} from '../interfaces';


interface EditListProps extends RouteComponentProps<{id: string}> {

}

interface EditListState {
    members_of_group?: number[];
    user: User | null;
    all_members?: Member[];
}

export default  class EditList extends React.Component<EditListProps, EditListState>{    
    constructor(props) {
        super(props);
        this.state = {
            user: null,
        }; 
        this.call_api = this.call_api.bind(this);
    }

    componentDidMount() { 
        let user =UserStore.getUser();
        this.setState({user: user});

        this.call_api();
    }

    call_api(){

        fetch('/api/all_members', {method: "get"})
        .then(res => {
        if (res.status === 200) {
            res.json().then(data =>{
            this.setState({ all_members: data})
            })               
        } else {
            if (res.statusText == "Unauthorized"){
                let res = fetch('/api/logout',{method: 'get'});
                UserStore.removeAll();
                window.location.replace("/login");
                return;
            }
            const error = new Error(res.statusText);
            throw error;
        }
        })
        .catch(err => {
            alert("There has been a error, try agin!")
        });
    
        let url = "/api/list/" + this.props.match.params.id;
        fetch(url, {method: "get"})
            .then(res => {
            if (res.status === 200) {
                res.json().then(data =>{
                this.setState({ members_of_group: (data[0].members_in_list || []).map(m => m.member_id)})
                })
            } else {
                if (res.statusText == "Unauthorized"){
                    let res = fetch('/api/logout',{method: 'get'});
                    UserStore.removeAll();
                    window.location.replace("/login");
                    return;
                }
                const error = new Error(res.statusText);
                throw error;
            }
            })
            .catch(err => {
                alert("There has been a error, try agin!")
            });
    
    }

    async saveUsers(ids: number[]) {

        let res = await fetch("/api/edit_list", {method: "POST", body: JSON.stringify({
            id: parseInt(this.props.match.params.id),
            users_id: ids
        }), headers: {"content-type": "application/json"}})
        let result = await res.json();
        
        if (result && res.ok){
            window.location.reload(); // >:(
            console.log("saved")
        }
        else{
            alert("There has been a error, please try agin")
        }
    }

    render(){
        if (this.state.user === null) {
            return "Loading...";
        }

        console.log(this.state.members_of_group)
        if(this.state.all_members && this.state.members_of_group ){
            return(
                <div>
                    <SelectTableComponent 
                        all_members = {this.state.all_members}  
                        checkedUsers =  {this.state.members_of_group}
                        setMembersOfGroup={(members) => this.setState({members_of_group: members})}
                    />

                    <button onClick={() => {
                        this.saveUsers(this.state.members_of_group);
                    }} className="absolute bottom-0 w-full h-20 text-3xl bg-green-400 hover:bg-green-500 text-white font-bold " >
                        Save
                    </button>

                </div>
            );
    }
    else{
        return(
            <div>

            </div>
        );
    }
    }
}

