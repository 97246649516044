
export default function AllLists(props)  {
    
    async function delete_list( list_id , list_name){
        if (window.confirm('Are you sure you want to delete -> '+ list_name)) {
            let res = await fetch("/api/delete_list",{
                method: "DELETE",
                headers: {
                    'Content-type': 'application/json; charset=UTF-8' 
                },
                body: JSON.stringify({
                    "id" : list_id
                })
            });
            
            if (res.status === 200){
                console.log("Removed the list")
                window.location.reload()
            }
            else{
                alert("Cant remove the list, try agin!")
            }
        }
    }
    
    if (props.all_lists.length == 0){
        return(
            <div class="container mx-auto">
                <p class="mt-10">No Lists...</p>
            </div>
        );
    }
  return (
    <div className="container mx-auto">

        <div className="p-2 my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 ">
                    <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                            <table className="min-w-full divide-y divide-gray-200 table-auto" id="all_members_table">
                            <thead className="bg-gray-50">
                                <tr>
                                    <th 
                                        scope="col-2"
                                        className=" px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                    Namn
                                    </th>
                                    <th 
                                        scope="col-2"
                                        className=" px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                    >
                                    How many members in list
                                    </th>
                
                                    <th scope="col">
                                    </th>
                                    <th scope="col">
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {props.all_lists.map((list) => (
                                    <tr key={list.id}>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{list.name}</td>
                                        <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{list.members_in_list.length}</td>
        

                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                            
                                            <a href={"/edit_list/"+ list.id} className="text-indigo-600 hover:text-indigo-900 ">
                                                Edit
                                            </a>
                                        </td>
                                        <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                            <button class="m-0 inline-flex items-center justify-center w-10 h-10 text-indigo-100 transition-colors duration-150 rounded-lg focus:shadow-outline hover:bg-red-100"
                                                onClick={() => delete_list(list.id, list.name)}
                                            >
                                                <svg class="m-2" style={{stroke:"#FF0000", strokeWidth: "1"}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path fill="none" d="M12.71,7.291c-0.15-0.15-0.393-0.15-0.542,0L10,9.458L7.833,7.291c-0.15-0.15-0.392-0.15-0.542,0c-0.149,0.149-0.149,0.392,0,0.541L9.458,10l-2.168,2.167c-0.149,0.15-0.149,0.393,0,0.542c0.15,0.149,0.392,0.149,0.542,0L10,10.542l2.168,2.167c0.149,0.149,0.392,0.149,0.542,0c0.148-0.149,0.148-0.392,0-0.542L10.542,10l2.168-2.168C12.858,7.683,12.858,7.44,12.71,7.291z M10,1.188c-4.867,0-8.812,3.946-8.812,8.812c0,4.867,3.945,8.812,8.812,8.812s8.812-3.945,8.812-8.812C18.812,5.133,14.867,1.188,10,1.188z M10,18.046c-4.444,0-8.046-3.603-8.046-8.046c0-4.444,3.603-8.046,8.046-8.046c4.443,0,8.046,3.602,8.046,8.046C18.046,14.443,14.443,18.046,10,18.046z"></path></svg>
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                </table>
            </div>
        </div>
    </div>

    </div>
  )
}