import React from 'react';
import { observer } from 'mobx-react';
import LoginForm from '../components/form/LoginForm'
import UserStore from '../components/auth/UserStore';
import { Redirect } from 'react-router-dom';


class LoginScreen extends React.Component {

  render() {
    if (UserStore.checkIfLoggedIn()){
      return <Redirect to="/" />;
    }
    return(
      <LoginForm />
    );
  }
}

export default observer(LoginScreen);
