import React , { useEffect } from "react"
import UserStore from '../components/auth/UserStore'

import AllaMembers from "../components/AllMembers";
import AddMembers from "../components/form/AddMembers";
class Members extends React.Component{    
    constructor() {
        super();
        this.state = {
            user: null,
            all_members: []
        }; 
    }
    
    componentDidMount() { 
        let user =UserStore.getUser();
        this.setState({user: user});


        fetch('/api/all_members', {method: "get"})
            .then(res => {
            if (res.status === 200) {
                res.json().then(data =>{
                this.setState({ all_members: data})
                })               
            } else {
                if (res.statusText == "Unauthorized"){
                    let res = fetch('/api/logout',{method: 'get'});
                    UserStore.removeAll();
                    window.location.replace("/login");
                    return;
                }
                const error = new Error(res.error);
                throw error;
            }
            })
            .catch(err => {
                alert("There has been a error, try agin!")
            });
    }
 
    render(){
        if (this.state.user == null) {
            return "Loading...";
        }
        return(
            <div>

                <AddMembers />
                
                <AllaMembers 
                    all_members = {this.state.all_members}
                />
            </div>
        );
    }
}

export default Members;